<template>
  <inline-svg v-if="this.image" :src="image" :class="['sunbed-container', classes]" />
</template>

<style lang="scss">
@import "@/assets/sass/sunbeds/index.scss";
</style>

<script>
/* eslint-disable global-require */
import InlineSvg from 'vue-inline-svg';
import equipmentService from '@/libs/equipment';

import Equipment from '@/libs/classes/equipment';

export default {
  name: 'sunbedImage',
  components: {
    InlineSvg,
  },
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },

    noShadow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes () {
      if (!this.equipment) {
        return null;
      }

      const classes = [];

      // Shadow
      if (this.noShadow === true) {
        classes.push('no-shadow');
      }

      // Luxura Vegaz
      if (this.equipment.model === 'luxura_vegaz_9200' || this.equipment.model === 'luxura_vegaz_8200') {
        classes.push('luxura-vegaz');

        // Open/closed based on state
        classes.push(equipmentService.inSessionStates.includes(this.equipment.state) === true ? 'canopy-closed' : 'canopy-open');

        if (this.equipment.model === 'luxura_vegaz_9200') {
          classes.push('luxura-vegaz-9200');
        }

        if (this.equipment.model === 'luxura_vegaz_8200') {
          classes.push('luxura-vegaz-8200');
        }

        // Equipment options
        if (this.equipment.specifications && this.equipment.specifications.options) {
          // Extension
          if (this.equipment.specifications.options.indexOf('Shoulder tanner + SoundAround Plus') !== -1) {
            classes.push('extension');
          }

          // Luxura Vegaz 8200 - Luxury Front Panel
          if (this.equipment.model === 'luxura_vegaz_8200' && this.equipment.specifications.options.indexOf('Luxury Front Panel') !== -1) {
            classes.push('luxury-front');
          }
        }

        // Equipment colors
        if (this.equipment.specifications && this.equipment.specifications.colors) {
          const colorClasses = [];

          // Color
          const sunbedColor = (this.equipment.specifications.colors || []).find((color) => color.location === 'sunbed');
          if (sunbedColor && sunbedColor.color) {
            // Set color of sunbed
            switch (sunbedColor.color) {
              case 'Elite Gold':
                colorClasses.push('sunbed-color--gold');
                break;
              case 'Dynamic Red':
                colorClasses.push('sunbed-color--red');
                break;
              case 'Stylish Silver':
                colorClasses.push('sunbed-color--grey');
                break;
              default:
                colorClasses.push('sunbed-color--gold');
            }
          } else {
            colorClasses.push('sunbed-color--gold');
          }

          // Add color classes
          classes.push(...(colorClasses || []));
        }
      }

      // Luxura X7
      if (this.equipment.model === 'luxura_x7') {
        classes.push('luxura-x7');

        // Open/closed based on state
        classes.push(equipmentService.inSessionStates.includes(this.equipment.state) === true ? 'canopy-closed' : 'canopy-open');

        // Equipment options
        if (this.equipment.specifications && this.equipment.specifications.options) {
          // QSens
          if (this.equipment.specifications.options.indexOf('Qsens') !== -1) {
            classes.push('airpiece');
          }

          // Ambient FlowLight Eyeliners
          if (this.equipment.specifications.options.indexOf('Ambient FlowLight Eyeliners') !== -1) {
            classes.push('ambient-eyeliners');
          }

          // Inox steel polished Decoshield & Brandshield
          if (this.equipment.specifications.options.indexOf('Inox steel polished Decoshield & Brandshield') !== -1) {
            classes.push('luxury-front-panel');
          }
        }
      }

      // Luxura X5
      if (this.equipment.model === 'luxura_x5') {
        classes.push('luxura-x5');

        // Open/closed based on state
        classes.push(equipmentService.inSessionStates.includes(this.equipment.state) === true ? 'canopy-closed' : 'canopy-open');

        // Equipment options
        if (this.equipment.specifications && this.equipment.specifications.options) {
          // Centre line
          if (this.equipment.specifications.options.indexOf('Ambient FlowLight Centre line') !== -1) {
            classes.push('flowlight-centre-line');
          }

          // Connect line
          if (this.equipment.specifications.options.indexOf('Ambient FlowLight Connect line') !== -1) {
            classes.push('flowlight-connect-line');
          }

          // QSens
          if (this.equipment.specifications.options.indexOf('Qsens') !== -1) {
            classes.push('airpiece');
          }

          // Luxury Front Panel
          if (this.equipment.specifications.options.indexOf('Luxury Front Panel with Brandshield and Ambient FlowLight') !== -1) {
            classes.push('luxury-front-panel');
          }
        }
      }

      // Luxura V6
      if (this.equipment.model === 'luxura_v6') {
        classes.push('luxura-v6');

        // Open/closed based on state
        classes.push(equipmentService.inSessionStates.includes(this.equipment.state) === true ? 'door-closed' : 'door-open');

        // Equipment options
        if (this.equipment.specifications && this.equipment.specifications.options) {
          // Twin color gold
          if (this.equipment.specifications.options.indexOf('Twin Colour Gold') !== -1) {
            classes.push('twin-color twin-color--gold');
          }
        }
      }

      // Luxura V8
      if (this.equipment.model === 'luxura_v8') {
        classes.push('luxura-v8');

        // Open/closed based on state
        classes.push(equipmentService.inSessionStates.includes(this.equipment.state) === true ? 'door-closed' : 'door-open');

        // Equipment options
        if (this.equipment.specifications && this.equipment.specifications.options) {
          // Twin color gold
          if (this.equipment.specifications.options.indexOf('Walnut Wooden Handle') !== -1) {
            classes.push('handle--wood');
          }
        }
      }

      return classes;
    },

    image () {
      if (!this.equipment) {
        return null;
      }

      // Luxura Jewel
      if (this.equipment.model === 'luxura_jewel') {
        if (equipmentService.inSessionStates.includes(this.equipment.state) === true) {
          return require('@/assets/images/sunbeds/luxura_jewel-closed.svg');
        }
        return require('@/assets/images/sunbeds/luxura_jewel-open.svg');
      }

      // Luxura Vegaz 9200
      if (this.equipment.model === 'luxura_vegaz_9200' || this.equipment.model === 'luxura_vegaz_8200') {
        // When the equipment has color 'Crystal White', use another SVG
        if (this.equipment.specifications && this.equipment.specifications.colors) {
          // Color
          const sunbedColor = (this.equipment.specifications.colors || []).find((color) => color.location === 'sunbed');
          if (sunbedColor && sunbedColor.color && sunbedColor.color === 'Crystal White') {
            return require('@/assets/images/sunbeds/luxura_vegaz_crystal_white.svg');
          }
        }

        return require('@/assets/images/sunbeds/luxura_vegaz.svg');
      }

      // Luxura X7
      if (this.equipment.model === 'luxura_x7') {
        return require('@/assets/images/sunbeds/luxura_x7.min.svg');
      }

      // Luxura X5
      if (this.equipment.model === 'luxura_x5') {
        return require('@/assets/images/sunbeds/luxura_x5.min.svg');
      }

      // Luxura V6
      if (this.equipment.model === 'luxura_v6') {
        return require('@/assets/images/sunbeds/luxura_v6.min.svg');
      }

      // Luxura V8
      if (this.equipment.model === 'luxura_v8') {
        return require('@/assets/images/sunbeds/luxura_v8.min.svg');
      }

      return null;
    },
  },
};
</script>
